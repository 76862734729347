import React from 'react';
import { connect } from 'react-redux';
import _trim from 'lodash/trim';
import _isEmpty from 'lodash/isEmpty';
import ProfileFormComponent from '../components/profile/profile-form/profile-form';
import { updateUserProfile } from '../redux/actions/profile';
import { logoutUser } from '../redux/actions/auth';

class WithAddressCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.profile !== prevState.profile) {
      return {
        ...prevState,
        profile: nextProps.profile
      };
    }
    return null;
  }

  handleProfileChange = (profile) => {
    this.setState({
      profile
    });
  };

  formIsDirty = () => {
    if (_isEmpty(_trim(this.state.profile.dob))) {
      return true;
    }
    if (_isEmpty(_trim(this.state.profile.address.street_address))) {
      return true;
    }
    /* if (_isEmpty(_trim(this.state.profile.address.postcode))) {
        return true;
      } */
    if (_isEmpty(_trim(this.state.profile.address.city))) {
      return true;
    }
    /* if (_isEmpty(_trim(this.state.profile.address.country))) {
        return true;
      } */
    if (_isEmpty(_trim(this.state.profile.contact.mobile))) {
      return true;
    }

    if (_isEmpty(_trim(this.state.profile.address.state))) {
      return true;
    }

    if (_isEmpty(_trim(this.state.profile.gender))) {
      return true;
    }
    return false;
  };

  submitForm = () => {
    this.props.updateUserProfile(this.state.profile);
  };

  render() {
    return (
      <>
        {this.props.children}
        {this.props.auth.isAddressComplete === 'no' && (
          <div className="overlay-dark">
            <div className="modal-container">
              <div className="close-icon" onClick={this.props.logoutUser}>
                &times;
              </div>
              <h2>Complete Profile</h2>
              <p>Please fill the information below to complete sign-up</p>
              <ProfileFormComponent
                profile={this.state.profile}
                onProfileChange={this.handleProfileChange}
                editMode
              />
              <div className="verify text-center">
                <button
                  className="btn save"
                  disabled={this.formIsDirty()}
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  auth,
  profile
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (profile) => dispatch(updateUserProfile(profile)),
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(WithAddressCheck);
