import React, { Component } from 'react';
import _trim from 'lodash/trim';
import _isEmpty from 'lodash/isEmpty';
import ProfileFormComponent from './profile-form/profile-form';
// import PasswordFormComponent from './password-form';
import { connect } from 'react-redux';
import {
  fetchUserProfile,
  updateUserProfile
} from '../../redux/actions/profile';

class ProfileComponent extends Component {
  state = {
    editMode: false
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      profile: props.profile
    };
  }

  handleProfileChange = (profile) => {
    this.setState({
      profile
    });
  };

  submitForm = () => {
    this.props.updateUserProfile(this.state.profile);
    this.setState({
      editMode: false
    });
  };

  resetForm = () => {
    this.setState({
      profile: this.props.profile,
      editMode: false
    });
  };

  formIsDirty = () => {
    if (_isEmpty(_trim(this.state.profile.dob))) {
      return true;
    }
    if (_isEmpty(_trim(this.state.profile.address.street_address))) {
      return true;
    }
    /* if (_isEmpty(_trim(this.state.profile.address.postcode))) {
      return true;
    } */
    if (_isEmpty(_trim(this.state.profile.address.city))) {
      return true;
    }
    /* if (_isEmpty(_trim(this.state.profile.address.country))) {
      return true;
    } */
    if (_isEmpty(_trim(this.state.profile.contact.mobile))) {
      return true;
    }

    if (_isEmpty(_trim(this.state.profile.address.state))) {
      return true;
    }

    if (_isEmpty(_trim(this.state.profile.gender))) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.state.profile) return null;

    return (
      <div>
        <section className="account-page">
          <div className="container">
            <div className="head-area">
              <h2 className="wow bounceInLeft">Profile</h2>
              {!this.state.editMode && (
                <div
                  className="edit-icon"
                  onClick={() =>
                    this.setState({
                      editMode: true
                    })
                  }
                >
                  <i className="fas fa-pencil-alt"></i> Edit
                </div>
              )}
            </div>
            <div>
              <div className="accordion-content-wrap">
                <ProfileFormComponent
                  profile={this.state.profile}
                  onProfileChange={this.handleProfileChange}
                  editMode={this.state.editMode}
                />
              </div>
              {this.state.editMode && (
                <div className="verify text-center">
                  <div className="container">
                    <button
                      className="btn save"
                      disabled={this.formIsDirty()}
                      onClick={this.submitForm}
                    >
                      Save
                    </button>
                    <button className="btn cancel" onClick={this.resetForm}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  updateUserProfile: (payload) => dispatch(updateUserProfile(payload))
});

const mapStateToProps = ({ profile }) => ({
  profile
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
