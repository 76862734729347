export const WINNERS = 'WINNERS';
export const PROFILE = 'PROFILE';
export const AUTH = 'AUTH';
export const DAILY_DRAW = 'DAILY_DRAW';
export const DAILY_DRAW_QUESTIONS = 'DAILY_DRAW';
export const PATCH_ADDRESS = 'PATCH_ADDRESS';
export const REF_CODE = 'REF_CODE';
export const USER_DETAILS = 'USER_DETAILS';
export const BANK_DETAILS = 'BANK_DETAILS';
export const WALLET_TRANSACTIONS = 'WALLET_TRANSACTIONS';
export const WALLET_DETAILS = 'WALLET_DETAILS';
export const WITHDRAW_BALANCE = 'WITHDRAW_BALANCE';
export const DAILY_DRAW_WINNERS = 'DAILY_DRAW_WINNERS';
