import React, { Component } from 'react';
import mailbox from '../../images/icons/mailbox.png';
import sudoku from '../../images/icons/sudoku.png';
import check from '../../images/icons/check.png';

class HowItWorksComponent extends Component {
  componentDidMount() {
    document.title = 'How it works';
  }

  render() {
    return (
      <div>
        <section className="quote">
          <div className="container">
            <p className="lead text-center">
              “We have variety of interesting competitions where players can win
              amazing presents. Below is a general description on how you can
              participate. For more detailed information on different games
              check out the games home page.”
            </p>
          </div>
        </section>
        <section className="how-work  text-center ">
          <h2> How it works </h2>
          <div className="container">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="icon">
                <img src={mailbox} alt="Postcode" />
              </div>
              <h3>Signup with Facebook</h3>
              <p>
                To be eligible to participate in any of the games, you need to
                signup/register through facebook. Complete your profile by
                giving some extra details about yourself. This information is only
                used to enhance your experience of the platform
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="icon">
                <img src={sudoku} alt="Games" />
              </div>
              <h3>Play &amp; Choose a game</h3>
              <p>
                Choose a game that interests you and play. Most games will only
                let you participate once daily
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="icon">
                <img src={check} alt="Check daily" />
              </div>
              <h3>Check if you've won </h3>
              <p>
                Most of the games on freerabba have a 24hr lifecycle. At the end
                of each cycle, winners will be declared. Check to see if you are
                a winner and claim your prize.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HowItWorksComponent;
