import * as constants from './common/constants';

/**
 * Base Url of API server.  Important to append ending slash.
 */
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;


/**
 * Api configuration for each resource, add a types for auto generated action and reducer in redux
 */


export const config = {
  [constants.WINNERS]: {
    url: `${BASE_URL}users/winners`
  },
  [constants.DAILY_DRAW_WINNERS]: {
    url: `${BASE_URL}daily_draw/winners`
  },
  [constants.PROFILE]: {
    url: `${BASE_URL}profile`
  },
  [constants.AUTH]: {
    url: `${BASE_URL}users/convert_token`
  },
  [constants.DAILY_DRAW]: {
    url: `${BASE_URL}daily_draw`
  },
  [constants.PATCH_ADDRESS]: {
    url: `${BASE_URL}users/details`
  },
  [constants.REF_CODE]: {
    url: `${BASE_URL}users/referral`
  },
  [constants.USER_DETAILS]: {
    url: `${BASE_URL}users/m`
  },
  [constants.BANK_DETAILS]: {
    url: `${BASE_URL}wallet/bank_details`
  },
  [constants.WALLET_DETAILS]: {
    url: `${BASE_URL}wallet/overview`
  },
  [constants.WALLET_TRANSACTIONS]: {
    url: `${BASE_URL}wallet/transactions`
  },
  [constants.WITHDRAW_BALANCE]: {
    url: `${BASE_URL}wallet/withdraw`
  }
};


export const getConfig = type => {
  return config[type] || {}; // return empty so we don't crash
};
