import { DAILY_DRAW } from '../../common/constants';

export const FETCH_DAILY_DRAW_INVOKED = `${DAILY_DRAW}_FETCH_DAILY_DRAW`;
export const FETCH_DAILY_DRAW_DATA = `${DAILY_DRAW}_FETCH_DAILY_DRAW_DATA`;
export const FETCH_DAILY_DRAW_SUCCESS = `${DAILY_DRAW}_FETCH_DAILY_DRAW_SUCCESS`;
export const FETCH_DAILY_DRAW_FAILED = `${DAILY_DRAW}_FETCH_DAILY_DRAW_FAILED`;
export const FETCH_DAILY_DRAW_WINNERS = `${DAILY_DRAW}_FETCH_DAILY_DRAW_WINNERS`;
export const FETCH_DAILY_DRAW_WINNERS_INVOKED = `${DAILY_DRAW}_FETCH_DAILY_DRAW_WINNERS_INVOKED`;
export const FETCH_DAILY_DRAW_WINNERS_SUCCESS = `${DAILY_DRAW}_FETCH_DAILY_DRAW_SUCCESS`;
export const FETCH_DAILY_DRAW_WINNERS_FAILED = `${DAILY_DRAW}_FETCH_DAILY_DRAW_FAILED`;

export const FETCH_DAILY_DRAW_QUESTIONS_INVOKED = `${DAILY_DRAW}_FETCH_DAILY_DRAW_QUESTIONS_INVOKED`;
export const FETCH_DAILY_DRAW_QUESTIONS = `${DAILY_DRAW}_FETCH_DAILY_DRAW_QUESTIONS`;
export const INCREASE_QUESTION_INDEX = `${DAILY_DRAW}_INCREASE_QUESTION_INDEX`;
export const FETCH_DAILY_DRAW_QUESTIONS_SUCCESS = `${DAILY_DRAW}_FETCH_DAILY_DRAW_QUESTIONS_SUCCESS`;
export const FETCH_DAILY_DRAW_QUESTIONS_FAILED = `${DAILY_DRAW}_FETCH_DAILY_DRAW_QUESTIONS_FAILED`;

export const SUBMIT_ANSWERS_INVOKED = `${DAILY_DRAW}_SUBMIT_ANSWERS_INVOKED`;
export const SUBMIT_ANSWERS_SUCCESS = `${DAILY_DRAW}_SUBMIT_ANSWERS_SUCCESS`;
export const SUBMIT_ANSWERS_FAILED = `${DAILY_DRAW}_SUBMIT_ANSWERS_FAILED`;
export const SUBMIT_ANSWERS = `${DAILY_DRAW}_SUBMIT_ANSWERS`;
export const SET_QUIZ_TIMER = `${DAILY_DRAW}_SET_QUIZ_TIMER`;
export const RESET_QUIZ = `${DAILY_DRAW}_RESET_QUIZ`;

export const fetchDailyDrawInvoked = () => ({
  type: FETCH_DAILY_DRAW_INVOKED
});

export const fetchDailyDrawData = () => ({
  type: FETCH_DAILY_DRAW_DATA
});

export const fetchDailyDrawSuccess = (payload) => ({
  type: FETCH_DAILY_DRAW_SUCCESS,
  payload
});

export const fetchDailyDrawFailed = (error) => ({
  type: FETCH_DAILY_DRAW_FAILED,
  error
});

export const fetchDailyDrawQuestionsInvoked = () => ({
  type: FETCH_DAILY_DRAW_QUESTIONS_INVOKED
});

export const fetchDailyDrawQuestions = (quizId) => ({
  type: FETCH_DAILY_DRAW_QUESTIONS,
  quizId
});

export const increaseQuestionIndex = (payload) => ({
  type: INCREASE_QUESTION_INDEX,
  payload
});

export const fetchDailyDrawQuestionsSuccess = (payload) => ({
  type: FETCH_DAILY_DRAW_QUESTIONS_SUCCESS,
  payload
});

export const fetchDailyDrawQuestionsFailed = (error) => ({
  type: FETCH_DAILY_DRAW_QUESTIONS_FAILED,
  error
});

export const submitAnswersInvoked = () => ({
  type: SUBMIT_ANSWERS_INVOKED
});

export const submitAnswers = () => ({
  type: SUBMIT_ANSWERS
});

export const submitAnswersSuccess = (payload) => ({
  type: SUBMIT_ANSWERS_SUCCESS,
  payload
});

export const submitAnswersSuccessFailed = (error) => ({
  type: SUBMIT_ANSWERS_FAILED,
  error
});

export const setQuizTimer = (time) => ({
  type: SET_QUIZ_TIMER,
  time
});

export const resetQuiz = () => ({
  type: RESET_QUIZ
});

export const fetchDailyDrawWinners = () => ({
  type: FETCH_DAILY_DRAW_WINNERS,
});

export const fetchDailyDrawWinnersSuccess = (payload) => ({
  type: 'FETCH_DAILY_DRAW_WINNERS_SUCCESS',
  payload
});

export const fetchDailyDrawWinnersFailed = (payload) => ({
  type: 'FETCH_DAILY_DRAW_WINNERS_FAILED',
  payload
});

export const fetchDailyDrawWinnersInvoked = (payload) => ({
  type: 'FETCH_DAILY_DRAW_WINNERS_INVOKED',
  payload
});