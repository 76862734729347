import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { store } from './index';
import MainApp from './pages/main';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={MainApp} />
        </Switch>
      </BrowserRouter>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
}

export default App;
