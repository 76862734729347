import {
  fetchDailyDrawInvoked,
  fetchDailyDrawSuccess,
  fetchDailyDrawFailed,
  FETCH_DAILY_DRAW_DATA,
  FETCH_DAILY_DRAW_QUESTIONS,
  fetchDailyDrawQuestionsInvoked,
  fetchDailyDrawQuestionsSuccess,
  fetchDailyDrawQuestionsFailed,
  SUBMIT_ANSWERS,
  submitAnswersInvoked,
  submitAnswersSuccess,
  FETCH_DAILY_DRAW_WINNERS,
  fetchDailyDrawWinnersInvoked,
  fetchDailyDrawWinnersFailed,
  fetchDailyDrawWinnersSuccess,
} from '../../actions/daily-draw';
import {
  fetchDailyDraw,
  fetchDailyQuestions,
  submitQuiz,
  fetchScoreIfPlayed
} from '../../../services/daily-draw';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { isAuthenticated } from '../../../services/auth';
import { fetchDailyDrawWinners } from '../../../services/winners';
import { logoutUser } from '../../actions/auth';

function* fetchDaily() {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }
  try {
    yield put(fetchDailyDrawInvoked());
    const [payload] = yield call(fetchDailyDraw);
    const scoreResp = yield call(fetchScoreIfPlayed, payload);
    yield put(fetchDailyDrawSuccess(payload));
    if (scoreResp.length > 0) {
      // Score already exists for the quiz
      yield put(submitAnswersInvoked());
      yield put(submitAnswersSuccess(parseInt(scoreResp[0].score, 10)));
    }
  } catch (ex) {
    yield put(fetchDailyDrawFailed(ex));
  }
}

function* fetchDailyDrawQuestions({ quizId }) {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }
  try {
    yield put(fetchDailyDrawQuestionsInvoked());
    const payload = yield call(fetchDailyQuestions, quizId);

    yield put(fetchDailyDrawQuestionsSuccess(payload));
  } catch (ex) {
    yield put(fetchDailyDrawQuestionsFailed(ex));
  }
}

function* submitAnswers() {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }
  const { dailyDraw } = yield select();
  const { questions } = dailyDraw;

  yield put(submitAnswersInvoked());

  const payload = yield call(
    submitQuiz,
    questions,
    dailyDraw.daily_quiz.quiz_id
  );

  yield put(submitAnswersSuccess(payload));
}

function* fetchDailyDrawWinnersData() {
  try {
    yield put(fetchDailyDrawWinnersInvoked());
    const payload = yield call(fetchDailyDrawWinners);

    yield put(fetchDailyDrawWinnersSuccess(payload));
  } catch (ex) {
    yield put(fetchDailyDrawWinnersFailed(ex));
  }
}

export default function* () {
  yield takeLatest(FETCH_DAILY_DRAW_DATA, fetchDaily);
  yield takeLatest(FETCH_DAILY_DRAW_QUESTIONS, fetchDailyDrawQuestions);
  yield takeLatest(SUBMIT_ANSWERS, submitAnswers);
  yield takeLatest(FETCH_DAILY_DRAW_WINNERS, fetchDailyDrawWinnersData);

}
