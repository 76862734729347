import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_LOG_OUT_SUCCESS,
  AUTH_SET_ADDRESS_COMPLETION_STATUS
} from '../../actions/auth';

export default (state = {}, action) => {
  let newState;
  switch (action.type) {
  case AUTH_USER: {
    newState = Object.assign({ facebookResponse: action.payload }, state);
    return newState;
  }
  case AUTH_USER_SUCCESS: {
    return {
      ...state,
      canUseSite: !!action.response.isNewUser,
      ...action.response
    };
  }
  case AUTH_USER_LOG_OUT_SUCCESS:
    return {
      ...state,
      error: action.response.message,
      ...action.response
    };
  case AUTH_SET_ADDRESS_COMPLETION_STATUS:
    return {
      ...state,
      isAddressComplete: action.status
    };
  default:
    return state;
  }
};
