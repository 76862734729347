import {
  FETCH_USER_PROFILE_SUCCESS,
  INIT_USER_PROFILE,
  UPDATE_PAYMENT_DETAILS,
  INIT_WALLET_DETAILS
} from '../../actions/profile';
import { AUTH_USER_LOG_OUT_SUCCESS } from '../../actions/auth';

export default (state = {}, action) => {
  switch (action.type) {
  case FETCH_USER_PROFILE_SUCCESS:
    return {
      ...state,
      profile: action.payload
    };
  case INIT_USER_PROFILE:
    return {
      ...state,
      ...action.payload
    };
  case UPDATE_PAYMENT_DETAILS:
    return {
      ...state,
      paymentDetails: action.payload
    };
  case INIT_WALLET_DETAILS: {
    return {
      ...state,
      wallet: action.payload
    };
  }

  case AUTH_USER_LOG_OUT_SUCCESS: {
    return {};
  }
  default:
    return state;
  }
};
