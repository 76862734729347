import winners from './winners/winners-reducer';
import auth from './auth/index';
import profile from './profile/index';
import dailyDraw from './daily-draw/index';

export default {
  winners,
  auth,
  profile,
  dailyDraw
};
