import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import logo from '../../../images/logo.png';

class UltimateFooter extends Component {
  state = {
    listItems: [
      {
        label: 'About Us',
        url: '/aboutus'
      },
      {
        label: 'Rules',
        url: '/rules'
      },
      {
        label: 'How To Claim',
        url: '/how-to-claim'
      },
      // {
      //   label: 'How To Sponsor',
      //   url: '/sponsor'
      // },
      {
        label: 'Privacy Policy',
        url: '/privacy-policy'
      }
    ]
  };

  renderList() {
    return (
      <ul>
        {this.state.listItems.map((l, index) => {
          return (
            <li key={index}>
              <Link to={l.url}>
                <i className="fas fa-angle-double-right"></i>
                {l.label}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }

  shareOnFB = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://www.facebook.com/sharer/sharer.php?u=https://freerabba.com/?uref=${this.props.auth.user.refCode}&t=Win money by playing games at FreeRabba`;
    } else {
      url =
        'https://www.facebook.com/sharer/sharer.php?u=https://freerabba.com&t=Win money by playing games at FreeRabba';
    }
    window.open(
      url,
      'FBWindow',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
    );
    return false;
  };

  shareOnTwitter = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://twitter.com/intent/tweet?url=https://freerabba.com/?uref=${this.props.auth.user.refCode}&via=freerabba&text=Win money by playing games at FreeRabba`;
    } else {
      url =
        'https://twitter.com/intent/tweet?url=https://freerabba.com&via=freerabba&text=Win money by playing games at FreeRabba';
    }
    window.open(url, 'TwitterWindow', 'width=600,height=300');
    return false;
  };

  shareOnWhatsApp = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://api.whatsapp.com/send?text=Win money by playing games at FreeRabba www.freerabba.com/?uref=${this.props.auth.user.refCode}`;
    } else {
      url =
        'https://api.whatsapp.com/send?text=Win money by playing games at FreeRabba www.freerabba.com';
    }
    window.open(url, 'WhatsappWindow', 'width=600,height=500');
    return false;
  };

  render() {
    const items = this.renderList();
    return (
      <section className="u-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 social-wrap">
              <div className="logo">
                <img src={logo} alt="FreeRabba" />
              </div>
              <div className="social">
                <i className="fab fa-facebook-f" onClick={this.shareOnFB}></i>
                <i className="fab fa-twitter" onClick={this.shareOnTwitter}></i>
                <i
                  className="fab fa-whatsapp"
                  onClick={this.shareOnWhatsApp}
                ></i>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="quicklink">
                <h3> Quick Links </h3>
                {items}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="f-contact">
                <h3> Contact Us</h3>
                <ul>
                  <li>
                    <a href="tel:+15589554885">
                      <i className="far fa-phone"></i> &nbsp; &nbsp;0705 768
                      9854
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@freerabba.com">
                      <i className="fas fa-envelope"></i> &nbsp;
                      &nbsp;info@freerabba.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default UltimateFooter;
