import { getConfig } from '../../apiConfig';
import { WINNERS, DAILY_DRAW_WINNERS } from '../../common/constants';
import { handleErrors } from '../../common';
import { get } from '../../common/apiClient';

export const fetchData = () => {
  const url = getConfig(WINNERS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${WINNERS} url`));
};

export const fetchDailyDrawWinners = () => {
  const url = getConfig(DAILY_DRAW_WINNERS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then(res => res.json())
      .catch(er => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${WINNERS} url`));
};