import { getConfig } from '../../apiConfig';
import {
  PROFILE,
  PATCH_ADDRESS,
  BANK_DETAILS,
  WALLET_DETAILS,
  WALLET_TRANSACTIONS,
  WITHDRAW_BALANCE
} from '../../common/constants';
import { handleErrors } from '../../common';
import { get, put } from '../../common/apiClient';

export const fetchUserData = () => {
  const url = getConfig(PROFILE).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${PROFILE} url`));
};

export const fetchPaymentDetails = () => {
  const url = getConfig(BANK_DETAILS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${BANK_DETAILS} url`));
};

export const fetchWalletDetails = () => {
  const url = getConfig(WALLET_DETAILS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then(async (res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${WALLET_DETAILS} url`));
};

export const fetchWalletTransactions = () => {
  const url = getConfig(WALLET_TRANSACTIONS).url;
  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${WALLET_TRANSACTIONS} url`));
};

export const saveUserData = (data) => {
  const url = getConfig(PATCH_ADDRESS).url;
  const reqData = {
    // "user": 0,
    house_number: data.address.house_number,
    street: data.address.street_address,
    telephone: data.contact.mobile,
    city: data.address.city,
    state: data.address.state,
    // country: data.address.country,
    // postcode: data.address.postcode,
    gender: data.gender,
    dob: data.dob
  };
  if (url) {
    return put(url, reqData)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${PATCH_ADDRESS} url`));
};

export const savePaymentData = (data) => {
  const url = getConfig(BANK_DETAILS).url;
  const reqData = {
    bank_name: data.provider,
    account_number: data.account_number
  };
  if (url) {
    return put(url, reqData)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${PATCH_ADDRESS} url`));
};

export const withdrawWalletBalance = (data) => {
  const url = getConfig(WITHDRAW_BALANCE).url;
  const reqData = {
    amount: data
  };
  if (url) {
    return put(url, reqData)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${WITHDRAW_BALANCE} url`));
};
