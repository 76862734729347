import React, { Component } from 'react';
import dailyquiz from '../../images/auth-pages/quiz.jpg';
import lottery from '../../images/auth-pages/lottery.jpg';
import tetris from '../../images/auth-pages/tetris.jpg';
import flashdraw from '../../images/auth-pages/flashdraw.jpg';
import sudoku from '../../images/auth-pages/sudoku.jpg';
import topvideos from '../../images/auth-pages/topvideos.jpg';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class GameComponent extends Component {
  gamesList = [
    {
      label: 'Daily Quiz',
      route: 'dailyquiz',
      status: '$100', // or 'DONE'
      img: dailyquiz
    },
    {
      label: 'Lottery',
      route: 'lottery',
      status: 'DONE',
      img: lottery
    },
    {
      label: 'Tetris',
      route: 'tetris',
      status: '$50',
      img: tetris
    },
    {
      label: 'Flash Draw',
      route: 'flashdraw',
      status: '$50',
      img: flashdraw
    },
    {
      label: 'Sudoku',
      route: 'sudoku',
      status: '$100',
      img: sudoku
    },
    {
      label: 'Top Videos',
      route: 'topvideos',
      status: '$50',
      img: topvideos
    }
  ];

  render() {
    if (this.props.isAuth) {
      return (
        <section className="games">
          <div className="container">
            <h2>Games</h2>
            <div className="games-wrap">
              <div className="game-image">
                <Link to={'dailyquiz'}>
                  {' '}
                  <img src={dailyquiz} alt="Daily Quiz" />
                </Link>
              </div>
              {/* <div className="game-image">
                <Link to={'dailyquiz'}> <img src={lottery} alt="Lottery"/></Link>
              </div>
              <div className="game-image">
                <Link to={'dailyquiz'}> <img src={tetris} alt="Tetris Game"/></Link>
              </div>
              <div className="game-image">
                <Link to={'dailyquiz'}> <img src={flashdraw} alt="Flash Draw"/></Link>
              </div>
              <div className="game-image">
                <Link to={'dailyquiz'}> <img src={sudoku} alt="Sudoku"/> </Link>
              </div>
              <div className="game-image">
                <Link to={'dailyquiz'}> <img src={topvideos} alt="Top Videos"/></Link>
              </div> */}
            </div>
          </div>
        </section>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  // redirect: () => push('/')
});
const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
  canUseSite: auth.canUseSite
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GameComponent));
