import React, { Component } from 'react';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

const DEFAULT_STATE = 'Select State';

class ProfileFormComponent extends Component {
  statesList = [
    DEFAULT_STATE,
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Enugu',
    'Edo',
    'Ekiti',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
    'Abuja'
  ];

  isValid = (name, val) => {
    switch (name) {
    case 'contact.email':
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val);
    case 'contact.mobile':
      // return /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(val);
      return /^[0-9]*$/.test(val);
    case 'address.postcode':
      return /^[0-9]*$/.test(val);

    case 'address.state':
      return val !== DEFAULT_STATE;
    default:
      return true;
    }
  };

  handleChange = (evt) => {
    const { profile } = this.props;
    const propPath = evt.target.name.split('.');

    if (this.isValid(evt.target.name, evt.target.value)) {
      try {
        if (propPath.length === 1) {
          profile[propPath[0]] = evt.target.value;
        } else {
          profile[propPath[0]][propPath[1]] = evt.target.value;
        }

        this.props.onProfileChange(profile);
      } catch (e) {
        console.log(e);
        console.log(propPath);
      }
    } else {
      if (propPath.length === 1) {
        evt.target.value = profile[propPath[0]];
      } else {
        evt.target.value = profile[propPath[0]][propPath[1]];
      }
    }
  };

  getMaxLegalDate() {
    return moment().subtract('18', 'years').format('YYYY-MM-DD');
  }

  render() {
    const { profile, editMode } = this.props;

    return (
      <form>
        <div className="input-row">
          <label htmlFor="first-name">
            First Name <span>*</span>:
          </label>
          <input
            type="text"
            id="first-name"
            name={'first_name'}
            value={profile.first_name}
            onChange={this.handleChange}
            required
            autoFocus
            readOnly
          />
        </div>

        <div className="input-row">
          <label htmlFor="last-name">
            Last Name <span>*</span>:
          </label>
          <input
            type="text"
            id="last-name"
            name={'last_name'}
            value={profile.last_name}
            onChange={this.handleChange}
            required
            readOnly
          />
        </div>

        <div className="input-row">
          <label htmlFor="email">
            {' '}
            Email Address <span>*</span>:
          </label>
          <input
            type="email"
            id="email"
            name={'contact.email'}
            value={profile.contact.email}
            onChange={this.handleChange}
            required
            readOnly={!(_isEmpty(profile.contact.email))}
          />
        </div>

        <div className="input-row">
          <label htmlFor="house-number">
            {' '}
            House No. <span>*</span>:
          </label>
          <input
            type="text"
            id="house-number"
            name={'address.house_number'}
            value={profile.address.house_number}
            onChange={this.handleChange}
            maxLength={5}
            required
            readOnly={!editMode}
          />
        </div>

        <div className="input-row">
          <label htmlFor="street-address">
            {' '}
            Street Address <span>*</span>:
          </label>
          <input
            type="text"
            id="street-address"
            name={'address.street_address'}
            value={profile.address.street_address}
            onChange={this.handleChange}
            required
            readOnly={!editMode}
          />
        </div>

        <div className="input-row">
          <label htmlFor="city">
            {' '}
            City <span>*</span>:
          </label>
          <input
            type="text"
            id="city"
            name={'address.city'}
            value={profile.address.city}
            onChange={this.handleChange}
            required
            readOnly={!editMode}
          />
        </div>

        <div className="input-row">
          <label htmlFor="city">
            {' '}
            State <span>*</span>:
          </label>
          {!editMode && (
            <input
              type="text"
              value={profile.address.state}
              required
              readOnly
            />
          )}
          {editMode && (
            <select
              value={
                _isEmpty(profile.address.state)
                  ? this.statesList[0]
                  : profile.address.state
              }
              id="state"
              name={'address.state'}
              onChange={this.handleChange}
              required
              style={{ height: '30px' }}
            >
              {this.statesList.map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </select>
          )}
        </div>


        <div className="input-row">
          <label htmlFor="mobile-number">
            Mobile Number <span>*</span>:
          </label>
          <input
            type="tel"
            id="mobile-number"
            name={'contact.mobile'}
            value={profile.contact.mobile}
            onChange={this.handleChange}
            required
            readOnly={!editMode}
          />
        </div>

        <div className="input-row">
          <label htmlFor="date-of-birth">
            {' '}
            Date of Birth <span>*</span>:
          </label>
          <input
            type="date"
            id="date-of-birth"
            placeholder="DD/MM/YYYY"
            name={'dob'}
            // value={moment(profile.dob).format('YYYY-MM-DD')}
            value={profile.dob}
            onChange={this.handleChange}
            required
            max={this.getMaxLegalDate()}
            readOnly={!editMode}
          />
        </div>

        <div className="input-row">
          <label>
            {' '}
            Gender <span>*</span>:
          </label>
          <div className="inputs-wrap">
            {editMode ? (
              <>
                <input
                  onChange={this.handleChange}
                  required
                  name={'gender'}
                  checked={profile.gender === 'Male'}
                  value="Male"
                  type="radio"
                />
                <span>Male</span>
                <input
                  onChange={this.handleChange}
                  required
                  name={'gender'}
                  checked={profile.gender === 'Female'}
                  value="Female"
                  type="radio"
                />
                <span>Female</span>
              </>
            ) : (
              <input type="text" value={profile.gender} readOnly />
            )}
          </div>
        </div>
      </form>
    );
  }
}

ProfileFormComponent.propTypes = {
  onProfileChange: PropTypes.func.isRequired,
  profile: PropTypes.any.isRequired,
  editMode: PropTypes.bool.isRequired
};
export default ProfileFormComponent;
