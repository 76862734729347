import React, { Component } from 'react';
import _get from 'lodash/get';
import { fetchDailyDrawWinners } from '../../redux/actions/daily-draw';
import { fetchWinnersData } from '../../redux/actions/winners-action';
import {
  fetchDailyDrawData,
  fetchDailyDrawQuestions,
  increaseQuestionIndex,
  submitAnswers,
  setQuizTimer,
  resetQuiz
} from '../../redux/actions/daily-draw';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import GamesBar from '../../components/games-bar';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const QUES_TIME_SECS = 10;

class DailyQuizComponent extends Component {
  state = {
    nextDrawTime: null,
    quizDate: null
  };

  componentDidMount() {
    this.props.fetchDailyDrawWinners();
    this.props.fetchDailyDrawData();

    if (
      _get(this.props, 'dailyDraw.daily_quiz.date_expires') &&
      new Date(this.props.dailyDraw.daily_quiz.date_expires) < new Date()
    ) {
      this.props.resetQuiz();
    }

    const currDate = new Date();
    if (currDate.getUTCHours() < 24) {
      currDate.setUTCDate(currDate.getUTCDate());
    }
    this.setState({
      quizDate: `${currDate.getUTCDate()} ${
        months[currDate.getUTCMonth()]
      }, ${currDate.getUTCFullYear()}`
    });

    this.evalNextDrawTime();
    this.interval = setInterval(() => {
      const newTime = this.state.nextDrawTime - 1;
      if (newTime < 0) {
        this.props.resetQuiz();
        setTimeout(() => {
          this.props.fetchWinnersData();
          this.props.fetchDailyDrawData();
        });
        clearInterval(this.interval);
        this.evalNextDrawTime();
        return;
      }
      this.setState({
        nextDrawTime: newTime
      });
    }, 1000);

    if (this.props.dailyDraw.startPlay) {
      this.resetQuesInterval();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.quizInterval);
  }

  resetQuesInterval = () => {
    const { activeQuestionIndex, questions } = this.props.dailyDraw;
    const isLastQuestion = questions.length - 1 <= activeQuestionIndex;
    const action = !isLastQuestion ? 'Next' : 'Submit';

    this.quizInterval = setInterval(() => {
      const newTime = (this.props.quizTimer || QUES_TIME_SECS + 0.25) - 1;
      if (newTime < 1) {
        this.showNextQuestion(action);
        return;
      }
      this.props.setQuizTimer(newTime);
    }, 1000);
  };

  renderWinners = () => {
    const winnersList = _get(this.props, 'winners', []);

    if (winnersList.length === 0) {
      return 'No winners found.';
    }

    console.log(winnersList)

    return winnersList
      .map((winner) => (
        <div className="winner" key={winner.fb_id}>
          <img
            src={`https://graph.facebook.com/${winner.fb_id}/picture?type=small`}
            alt="winner"
          />
          <span>{winner.full_name}</span>
        </div>
      ));
  };

  handlePlayClick = () => {
    this.props.fetchDailyDrawQuestions(this.props.dailyDraw.daily_quiz.quiz_id);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.dailyDraw.questions.length === 0 &&
      this.props.dailyDraw.questions.length > 0
    ) {
      this.resetQuesInterval();
    }
  }

  renderQuestions = () => {
    const { activeQuestionIndex, questions } = this.props.dailyDraw;
    const isLastQuestion = questions.length - 1 === activeQuestionIndex;
    const activeQuestion = questions[activeQuestionIndex] || null;
    const buttonText = !isLastQuestion ? 'Next' : 'Submit';

    const questionsStyle = {
      display: 'block'
    };

    return (
      <div
        className="questions col-md-push-3 col-md-7 col-sm-8 col-sm-push-4 col-xs-12 "
        style={questionsStyle}
      >
        <div className="question-field">
          <div className="question-head">
            <h4>
              Question {activeQuestionIndex + 1} of {questions.length}
            </h4>
          </div>
          <div className="que-ans">
            <h3 className="question">
              {activeQuestion && activeQuestion.quote}
            </h3>
            <div className="answers">
              <div className="options-wrap">
                {activeQuestion &&
                  ['optionA', 'optionB', 'optionC', 'optionD'].map(
                    (opt) =>
                      activeQuestion[opt] && (
                        <div
                          className="option"
                          key={opt}
                          onClick={(e) => {
                            e.target.classList.toggle('selected');
                            this.showNextQuestion(
                              buttonText,
                              activeQuestion[opt]
                            );
                          }}
                        >
                          {activeQuestion[opt]}
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
        {activeQuestion && (
          <div className="countdown">
            <span className="timer"></span>
            <span>
              {Math.floor(this.props.quizTimer || QUES_TIME_SECS + 0.25)}
            </span>
          </div>
        )}
        <div className="daily-draw">
          <h3>Next draw starts in</h3>
          <span>{this.secsToHHMMSS(this.state.nextDrawTime)}</span>
        </div>
      </div>
    );
  };

  showNextQuestion = (action, ans = null) => {
    const { activeQuestionIndex } = this.props.dailyDraw;
    clearInterval(this.quizInterval);

    setTimeout(() => {
      document
        .querySelectorAll('.option')
        .forEach((opt) => opt.classList.remove('selected'));
      this.props.increaseQuestionIndex({
        questionIndex: activeQuestionIndex,
        answer: ans
      });

      if (action.toLowerCase() === 'submit') {
        this.props.submitAnswers();
      } else {
        this.props.setQuizTimer(QUES_TIME_SECS + 0.25);
        this.resetQuesInterval();
      }
    }, 250);
  };

  secsToHHMMSS = (secs) => {
    const secNum = parseInt(secs, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };

  secsToMMSS = (secs) => {
    const secNum = parseInt(secs, 10);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };

  evalNextDrawTime() {
    const time = Date.now();
    let nextDrawTime = new Date().setUTCHours(0, 0, 0);
    if (time > nextDrawTime) {
      nextDrawTime += 1 * 24 * 60 * 60 * 1000;
    }
    const timeDiffInSecs = parseInt((nextDrawTime - time) / 1000, 10);
    this.setState({
      nextDrawTime: timeDiffInSecs
    });
  }

  render() {
    const { startPlay, finishPlay, quizResult } = this.props.dailyDraw;
    const scores = quizResult >= 0 ? quizResult : 'N/A';

    return (
      <div>
        <section className="daily-d-content">
          <div className="container">
            {/* <GamesBar /> */}
            <h2>
              Daily Quiz <span>({this.state.quizDate})</span>
            </h2>
            <div className="row">
              <div className="ads-small col-xs-12 hidden-md hidden-lg">
                <div className="ad-here">Advertisement</div>
              </div>
              {this.props.dailyDraw.daily_quiz ? (
                <div className=" col-md-push-3 col-md-7 col-sm-push-4 col-sm-8 col-xs-12">
                  {!startPlay && !finishPlay && (
                    <div className="play">
                      <p className="hidden-xs">
                        To stand a chance of winning today&apos;s draw, click
                        the button below to play the daily quiz.
                      </p>
                      <button className="btn" onClick={this.handlePlayClick}>
                        {' '}
                        Play Today&apos;s Quiz to Win ₦1000
                      </button>
                    </div>
                  )}
                  {finishPlay && scores === 'N/A' && (
                    <div className="daily-results">
                      <div className="score-result-box text-center">
                        <div className="quiz-actual-score">
                          Calculating score...
                        </div>
                      </div>
                    </div>
                  )}
                  {finishPlay && scores !== 'N/A' && (
                    <div className="daily-results">
                      <div className="score-result-box">
                        <div className="quiz-actual-score">
                          You scored <span className="result">{scores}</span>
                        </div>
                        <div className="score-status">
                          {scores >= 3 ? (
                            <i className="fa fa-check-circle"></i>
                          ) : (
                            <i className="fa fa-times-circle"></i>
                          )}
                        </div>
                        <div className="score-status-text">
                          {scores >= 3 ? (
                            <>Qualified for today&apos;s draw</>
                          ) : (
                            <>Try again tomorrow</>
                          )}
                        </div>
                        <div className="score-status-subtext">
                          {scores >= 3 ? (
                            <>
                              Check back tomorrow to see if you are one of the
                              winners
                            </>
                          ) : (
                            <>
                              Minimum points to qualify are <span>3</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!startPlay && (
                    <div
                      className={`${
                        finishPlay ? 'finished-result' : ''
                      } next-round`}
                    >
                      Resets in {this.secsToHHMMSS(this.state.nextDrawTime)}
                    </div>
                  )}
                </div>
              ) : (
                <div className=" col-md-push-3 col-md-7 col-sm-push-4 col-sm-8 col-xs-12">
                  <p className="text-center">Loading Quiz...</p>
                </div>
              )}
              {startPlay && this.renderQuestions()}
              <div className="winner-container col-md-3 col-sm-4 col-sm-pull-8 col-xs-12 col-md-pull-7">
                <div className="previous-winners">
                  <h3> Previous Winners</h3>
                  {this.renderWinners()}
                </div>
              </div>
              <div className="ads col-md-2 hidden-sm hidden-xs">
                <div className="ad-here"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

DailyQuizComponent.propTypes = {
  fetchWinnersData: PropTypes.func.isRequired,
  fetchDailyDrawData: PropTypes.func.isRequired,
  fetchDailyDrawQuestions: PropTypes.func.isRequired,
  winners: PropTypes.object,
  dailyDraw: PropTypes.any,
  increaseQuestionIndex: PropTypes.func.isRequired,
  submitAnswers: PropTypes.func.isRequired,
  fetchScoreIfPlayed: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  fetchDailyDrawWinners: () => dispatch(fetchDailyDrawWinners()),
  fetchDailyDrawData: () => dispatch(fetchDailyDrawData()),
  fetchDailyDrawQuestions: (quizId) =>
    dispatch(fetchDailyDrawQuestions(quizId)),
  increaseQuestionIndex: (nextQuestIonIndex) =>
    dispatch(increaseQuestionIndex(nextQuestIonIndex)),
  submitAnswers: () => dispatch(submitAnswers()),
  setQuizTimer: (time) => dispatch(setQuizTimer(time)),
  resetQuiz: () => dispatch(resetQuiz())
});

const mapStateToProps = (state, ownProps) => ({
  winners: state.dailyDraw.winners,
  dailyDraw: state.dailyDraw,
  quizTimer: state.dailyDraw.quizTimer
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyQuizComponent);