import React, { Component } from 'react';
import UltimateFooter from './ultimate-footer';
import mtn from '../../images/Sponsors/mtn.png';
import dailymail from '../../images/Sponsors/dailymail.png';
import shell from '../../images/Sponsors/shell.png';
import forbes from '../../images/Sponsors/Forbes.png';
import WOW from 'wowjs';

class FooterComponent extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  }

  render() {
    return (
      <div>
        <section className="brands text-center">
          {/*          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-xs-6" data-wow-duration="1s" data-wow-delay="0s">
                <a href="https://forbes.com" target="_blank" rel="noopener noreferrer">
                  <img src={forbes} alt="Forbes"/>
                </a>
              </div>
              <div className="col-lg-3 col-xs-6" data-wow-duration="1s" data-wow-delay=".5s">
                <a href="https://dailymail.co.uk" target="_blank" rel="noopener noreferrer">
                  <img src={dailymail} alt="Daily Mail"/>
                </a>
              </div>
              <div className="col-lg-3 col-xs-6" data-wow-duration="1s" data-wow-delay="1s">
                <a href="https://mtn.com" target="_blank" rel="noopener noreferrer">
                  <img src={mtn} alt="MTN"/>
                </a>
              </div>
              <div className="col-lg-3 col-xs-6" data-wow-duration="1s" data-wow-delay="1.5s">
                <a href="https://shell.com" target="_blank" rel="noopener noreferrer">
                  <img src={shell} alt="Shell"/>
                </a>
              </div>
            </div>
          </div>*/}
        </section>
        <UltimateFooter auth={this.props.auth} />
        <footer>
          <h3 className="text-center">
            &copy; Copyright 2018 . FreeRabba . All rights Reserved
          </h3>
        </footer>
      </div>
    );
  }
}

export default FooterComponent;
