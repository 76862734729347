import {
  AUTH_USER_INVOKE,
  authUserSuccess,
  authUserFailed,
  AUTH_LOG_OUT,
  logoutUserSuccess,
  setAddressCompletionStatus
} from '../../actions/auth';
import { initUserProfile, initWalletDetails } from '../../actions/profile';
import { resetQuiz } from '../../actions/daily-draw';
import {
  authUser,
  getRefCode,
  getUserDetails,
  isAuthenticated,
  logout,
  setSession
} from '../../../services/auth';
import {
  fetchPaymentDetails,
  fetchWalletDetails,
  fetchWalletTransactions
} from '../../../services/profile';
import { put, call, takeLatest } from 'redux-saga/effects';

function* auth(fbResponse) {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const urefId = queryParams.get('uref');
    if (urefId) {
      fbResponse.response.code = urefId;
    }
    const payload = yield call(authUser, fbResponse);
    if (!payload.access_token) {
      yield put(authUserFailed({ message: 'No Access token' }));
    }
    payload.user = {
      fb_id: fbResponse.response.userID,
      profile_pic: `https://graph.facebook.com/${fbResponse.response.userID}/picture?type=small`
    };

    payload.status = 'success';
    setSession(payload);
    payload.isAuth = isAuthenticated();
    const refCodeResp = yield call(getRefCode);
    payload.user.refCode = refCodeResp;

    if (!payload.address_complete) {
      // Address incomplete, hence, signup
      yield put(setAddressCompletionStatus('no'));
      yield put(
        initUserProfile({
          first_name: fbResponse.response.first_name,
          last_name: fbResponse.response.last_name,
          dob: '',
          address: {
            street_address: '',
            // postcode: '',
            city: '',
            state: '',
            // country: '',
            house_number: ''
          },
          contact: {
            email: fbResponse.response.email,
            mobile: ''
          },
          gender: '',
          paymentDetails: {
            account_number: '',
            provider: ''
          }
        })
      );
    } else {
      const detailsResp = yield call(getUserDetails);
      const paymentResp = yield call(fetchPaymentDetails);
      const { balance = 0 } = yield call(fetchWalletDetails);
      const transactionsResp = yield call(fetchWalletTransactions);

      yield put(
        initUserProfile({
          first_name: fbResponse.response.first_name,
          last_name: fbResponse.response.last_name,
          dob: detailsResp.dob,
          address: {
            street_address: detailsResp.street,
            // postcode: '',
            city: detailsResp.city,
            state: detailsResp.state,
            // country: '',
            house_number: detailsResp.house_number
          },
          contact: {
            email: fbResponse.response.email,
            mobile: detailsResp.telephone
          },
          gender: detailsResp.gender,
          paymentDetails: {
            account_name: paymentResp.account_number || '',
            provider: paymentResp.bank_name || ''
          }
        })
      );
      yield put(
        initWalletDetails({
          balance,
          transactions: transactionsResp
        })
      );
    }
    yield put(authUserSuccess(payload));
    yield put(resetQuiz());
  } catch (ex) {
    console.log(ex);
    yield put(authUserFailed(ex));
  }
}

function* authLogoutUser() {
  try {
    logout();
    yield put(
      logoutUserSuccess({
        isAuth: false,
        access_token: null,
        user: {},
        email: null,
        expiry_time: null
      })
    );
  } catch (ex) {
    console.log(ex);
    yield put(authUserFailed(ex));
  }
}

export default function* () {
  yield takeLatest(AUTH_USER_INVOKE, auth);
  yield takeLatest(AUTH_LOG_OUT, authLogoutUser);
}
