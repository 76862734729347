import React, { Component } from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import AuthComponent from '../auth';
import faq from '../../images/another.jpg';
import { connect } from 'react-redux';
import { fetchWinnersData } from '../../redux/actions/winners-action';
import { authUserInvoke } from '../../redux/actions/auth';

const $ = window.$;

class WinnersComponent extends Component {
  componentDidMount() {
    this.props.fetchWinnersData();

    $('.owl-carousel').owlCarousel({
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      center: true,
      loop: true,
      nav: true,
      dots: false,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });

    $('.owl-prev').html('<i class="fas fa-long-arrow-alt-left"></i>');
    $('.owl-next').html('<i class="fas fa-long-arrow-alt-right"></i>');
  }

  handleAuthentication = (authResult) => {
    if (authResult.accessToken) {
      this.props.authUserInvoke(authResult);
    }
  };

  render() {
    const winnersList = _get(this.props, 'winners.winners', []);
    return (
      <>
        <section className="header">
          {/*           <div className="overlay hidden-lg"></div> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xs-12 text">
                <div className="heading wow fadeInDown">
                  <h1>
                    Get Paid to Have <span>FUN. Sign Up to FreeRabba</span>
                  </h1>
                </div>
                <p className="lead wow slideInLeft">
                  Here at FreeRabba, we believe that you can earn money while
                  having fun. The idea is simple, sign in with your FaceBook
                  account, and you can be on your way to become one of our lucky
                  winners.
                </p>

                <div style={{ textAlign: 'center' }}>
                  <div className="btn">
                    <AuthComponent
                      onFacebookResponse={this.handleAuthentication}
                      label="Sign in with Facebook"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 hidden-xs image">
                <div className="h-image">
                  <div className="hidden-xs"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*        <section className="winners">
          <div className="container">
            <h2 className="text-center">Our Winners </h2>
            <div className="owl-carousel owl-theme">
              {
                Object.keys(winnersList).sort((a, b) => parseInt(a, 10) - parseInt(b, 10)).map(rank => (
                  <div className="item text-center" key={rank}>
                    <img src={`https://graph.facebook.com/${winnersList[rank].fb_id}/picture?type=large`} alt="winner"/>
                    <span>£${winnersList[rank].balance}</span>
                    <h4>{winnersList[rank].name}</h4>
                  </div>
                ))
              }
            </div>
          </div>
        </section>*/}
        <section className="faq">
          <div className="container-fluid">
            <h2 className="text-center">faq</h2>

            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                <div className="f-image">
                  <img src={faq} alt="FAQ" />
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                <div className="questions">
                  <div className="panel-group" id="accordion">
                    <div className="panel panel-default">
                      <div className="panel-heading one">
                        <h4 className="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                          >
                            Do I need to pay to signup?{' '}
                            <i className="fas fa-caret-down" />
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse">
                        <div className="panel-body">
                          Short answer is NO. FreeRabba is free to join and what
                          is more, we pay you to enjoy the awesome games on
                          here.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading two">
                        <h4 className="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                          >
                            How are winners chosen?{' '}
                            <i className="fas fa-caret-down"></i>
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTwo" className="panel-collapse collapse">
                        <div className="panel-body">
                          A robot randomly selects a winner or winners from a
                          pool of qualifying players. We honestly hope you love
                          robots!
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading three">
                        <h4 className="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                          >
                            Who has access to my information?{' '}
                            <i className="fas fa-caret-down"></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          We will never share your information without your
                          consent. And if you wish to end your association with
                          us, it's quite straightforward. Just click a button
                          and confirm.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading four">
                        <h4 className="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFour"
                          >
                            How do I access the games?{' '}
                            <i className="fas fa-caret-down"></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseFour"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          Anytime you are on the internet. We will make it
                          easier with daily reminders to visit the site and win.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading five">
                        <h4 className="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFive"
                          >
                            How do I claim?{' '}
                            <i className="fas fa-caret-down"></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseFive"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          Prizes can be cash or goods. Some games will
                          automatically add your winnings to your wallet. For
                          others, you have to click a button to accept your
                          prize. Check individual games for details
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

WinnersComponent.propTypes = {
  winners: PropTypes.any
};

const mapDispatchToProps = (dispatch) => ({
  fetchWinnersData: () => dispatch(fetchWinnersData()),
  authUserInvoke: (response) => dispatch(authUserInvoke(response))
});

const mapStateToProps = (state, ownProps) => ({
  winners: state.winners
});

export default connect(mapStateToProps, mapDispatchToProps)(WinnersComponent);
