import { getConfig } from '../../apiConfig';
import { DAILY_DRAW } from '../../common/constants';
import { handleErrors } from '../../common';
import { get, post } from '../../common/apiClient';

export const fetchDailyDraw = () => {
  const url = getConfig(DAILY_DRAW).url;

  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${DAILY_DRAW} url`));
};

export const fetchScoreIfPlayed = (quiz) => {
  const url = `${getConfig(DAILY_DRAW).url}/${quiz.quiz_id}/previous_answer`;

  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${DAILY_DRAW} PREVIOUS SCORE url`));
};

export const fetchDailyQuestions = (quizId) => {
  const url = `${getConfig(DAILY_DRAW).url}/${quizId}/`;

  if (url) {
    return get(url)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${DAILY_DRAW} url`));
};

export const submitQuiz = async (quiz, quizId) => {
  const url = `${getConfig(DAILY_DRAW).url}/${quizId}/answers`;

  if (url) {
    try {
      const response = await post(url, { user_response: quiz });

      return await response.json();
    } catch (err) {
      if (err.code === 401) handleErrors({ status: 401 });
      return err;
    }
  }
  return Promise.reject(new Error(`Missing ${DAILY_DRAW} url`));
};
