import { FETCH_WINNERS_SUCCESS } from '../../actions/winners-action';

export default (state = {}, action) => {
  switch (action.type) {
  case FETCH_WINNERS_SUCCESS:
    return {
      ...state,
      winners: action.payload
    };
  default:
    return state;
  }
};
