import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer, rootSaga } from './redux';
import createSagaMiddleware from 'redux-saga';
import { StateLoader } from './services/auth';

const stateLoader = new StateLoader();

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  stateLoader.loadState(),
  applyMiddleware(sagaMiddleWare)
);

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

sagaMiddleWare.run(rootSaga);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
