import { WINNERS } from '../../common/constants';

export const FETCH_WINNERS_INVOKED = `${WINNERS}_FETCH_WINNERS`;
export const FETCH_WINNERS_DATA = `${WINNERS}_FETCH_WINNERS_DATA`;
export const FETCH_WINNERS_SUCCESS = `${WINNERS}_FETCH_WINNERS_SUCCESS`;
export const FETCH_WINNERS_FAILED = `${WINNERS}_FETCH_WINNERS_FAILED`;

export const fetchWinnersInvoked = () => ({
  type: FETCH_WINNERS_INVOKED
});

export const fetchWinnersData = () => ({
  type: FETCH_WINNERS_DATA
});

export const fetchWinnersSuccess = (payload) => ({
  type: FETCH_WINNERS_SUCCESS,
  payload
});

export const fetchWinnersFailed = (error) => ({
  type: FETCH_WINNERS_SUCCESS,
  error
});
