import { AUTH } from '../../common/constants';

export const AUTH_USER = `${AUTH}AUTH_USER`;
export const AUTH_USER_INVOKE = `${AUTH}AUTH_USER_INVOKE`;
export const AUTH_USER_SUCCESS = `${AUTH}AUTH_USER_SUCCESS`;
export const AUTH_USER_FAILED = `${AUTH}AUTH_USER_FAILED`;
export const AUTH_LOG_OUT = `${AUTH}AUTH_LOG_OUT`;
export const AUTH_USER_LOG_OUT_SUCCESS = `${AUTH}AUTH_USER_LOGGED_OUT`;
export const AUTH_SET_ADDRESS_COMPLETION_STATUS = `${AUTH}AUTH_SET_ADDRESS_COMPLETION_STATUS`;

export const authUser = (response) => ({
  type: AUTH_USER,
  response
});

export const authUserInvoke = (response) => ({
  type: AUTH_USER_INVOKE,
  response
});

export const authUserSuccess = (response) => ({
  type: AUTH_USER_SUCCESS,
  response
});

export const authUserFailed = (response) => ({
  type: AUTH_USER_FAILED,
  response
});

export const logoutUser = (response) => ({
  type: AUTH_LOG_OUT,
  response
});

export const logoutUserSuccess = (response) => ({
  type: AUTH_USER_LOG_OUT_SUCCESS,
  response
});

export const setAddressCompletionStatus = (status) => ({
  type: AUTH_SET_ADDRESS_COMPLETION_STATUS,
  status
});
