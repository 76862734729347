import { toastr } from 'react-redux-toastr';
import {
  UPDATE_USER_PROFILE,
  fetchUserProfileInvoked,
  fetchUserProfileSuccess,
  fetchUserProfileFailed,
  // fetchPaymentDataSuccess,
  FETCH_USER_PROFILE,
  UPDATE_PAYMENT_DETAILS,
  WITHDRAW_BALANCE,
  initWalletDetails
  // FETCH_PAYMENT_DATA
} from '../../actions/profile';
import {
  fetchUserData,
  saveUserData,
  savePaymentData,
  withdrawWalletBalance,
  fetchWalletDetails,
  fetchWalletTransactions
} from '../../../services/profile';
import { put, call, takeLatest } from 'redux-saga/effects';
import { setAddressCompletionStatus } from '../../actions/auth';
import { isAuthenticated } from '../../../services/auth';
import { logoutUser } from '../../actions/auth';

function* fetchUserProfile() {
  try {
    yield put(fetchUserProfileInvoked());
    const payload = yield call(fetchUserData);
    yield put(fetchUserProfileSuccess(payload));
  } catch (ex) {
    yield put(fetchUserProfileFailed(ex));
  }
}

/* function* fetchPaymentData() {
  try {
    // yield put(fetchUserProfileInvoked());
    const payload = yield call(fetchPaymentDetails);
    // yield put(fetchPaymentDataSuccess(payload));
  } catch (ex) {
    yield put(fetchUserProfileFailed(ex));
  }
} */

function* updateUserProfile(payload) {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }
  try {
    // yield put(fetchUserProfileInvoked());
    const resp = yield call(saveUserData, payload.payload);
    if (resp.address_complete === 'success' && resp.message === 'success') {
      yield put(setAddressCompletionStatus('yes'));
    }
  } catch (ex) {
    yield put(fetchUserProfileFailed(ex));
  }
}

function* withdrawBalance({ payload }) {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }
  try {
    try {
      yield call(withdrawWalletBalance, payload);
      toastr.success(
        'Success!',
        'Your withdrawal request was successful. Please allow 3 - 5 business day for completion.'
      );
    } catch (err) {
      toastr.error(
        'Error!',
        'Error processing withdrawal request. Please try later or contact support.'
      );
    }
    const walletResp = yield call(fetchWalletDetails);
    const transactionsResp = yield call(fetchWalletTransactions);
    yield put(
      initWalletDetails({
        balance: walletResp.length > 0 ? walletResp[0].balance : 0,
        transactions: transactionsResp
      })
    );
  } catch (ex) {
    yield put(fetchUserProfileFailed(ex));
  }
}

function* updatePaymentDetails(payload) {
  if (!isAuthenticated()) {
    yield put(logoutUser());
    return;
  }

  try {
    yield call(savePaymentData, payload.payload);
  } catch (ex) {
    yield put(fetchUserProfileFailed(ex));
  }
}

export default function* () {
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfile);
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeLatest(UPDATE_PAYMENT_DETAILS, updatePaymentDetails);
  // yield takeLatest(FETCH_PAYMENT_DATA, fetchPaymentData);
  yield takeLatest(WITHDRAW_BALANCE, withdrawBalance);
}
