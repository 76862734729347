import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

const DEFAULT_PROVIDER = 'Select Bank';
class PaymentFormComponent extends Component {
  providers = [
    DEFAULT_PROVIDER,
    'Access Bank Plc',
    'Citibank Nigeria Limited',
    'Ecobank Nigeria Plc',
    'Fidelity Bank Plc',
    'FIRST BANK NIGERIA LIMITED',
    'First City Monument Bank Plc',
    'Globus Bank Limited',
    'Guaranty Trust Bank Plc',
    'Heritage Banking Company Ltd',
    'Key Stone Bank',
    'Polaris Bank',
    'Providus Bank',
    'Stanbic IBTC Bank Ltd',
    'Standard Chartered Bank Nigeria Ltd',
    'Sterling Bank Plc',
    'SunTrust Bank Nigeria Limited',
    'Titan Trust Bank Ltd',
    'Union Bank of Nigeria Plc',
    'United Bank For Africa Plc',
    'Unity Bank Plc',
    'Wema Bank Plc'
  ];

  handleChange = (evt) => {
    const { paymentDetails } = this.props;
    const propPath = evt.target.name;

    try {
      paymentDetails[evt.target.name] = evt.target.value;
      this.props.onPaymentChange(paymentDetails);

      if (evt.target.name === 'provider' && evt.target.value !== DEFAULT_PROVIDER){
        this.props.setFormValidity(true);
        return;
      }

      if (evt.target.name === 'account_number' && isNaN(evt.target.value)) {
        this.props.setFormValidity(false);
        return;
      }

      if (evt.target.name === 'account_number' && evt.target.value.length >= 10) {
        this.props.setFormValidity(true);
        return;
      }
      this.props.setFormValidity(false);
      return;
    } catch (e) {
      console.log(e);
      console.log(propPath);
    }
  };

  render() {
    const { paymentDetails, editMode } = this.props;

    return (
      <form>
        <div className="input-row">
          <label htmlFor="account-name">
            {' '}
            Account Number<span>*</span>:
          </label>
          <input
            type="text"
            id="account-name"
            name={'account_number'}
            value={paymentDetails.account_number}
            onChange={this.handleChange}
            required
            readOnly={!editMode}
          />
        </div>
        <div className="input-row">
          <label htmlFor="provider">
            Bank Name <span>*</span>:
          </label>
          {!editMode && (
            <input
              type="text"
              value={paymentDetails.provider}
              required
              readOnly
            />
          )}
          {editMode && <select
            value={
              _isEmpty(paymentDetails.provider)
                ? this.providers[0]
                : paymentDetails.provider
            }
            id="state"
            name={'provider'}
            onChange={this.handleChange}
            value={paymentDetails.provider}
            required
            style={{ height: '30px' }}
          >
            {this.providers.map((s) => (
              <option value={s} key={s}>
                {s}
              </option>
            ))}
          </select>}
        </div>
      </form>
    );
  }
}

PaymentFormComponent.propTypes = {
  onPaymentChange: PropTypes.func.isRequired,
  paymentDetails: PropTypes.any.isRequired
};
export default PaymentFormComponent;
