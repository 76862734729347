import React, { Component } from 'react';
import FooterComponent from '../../components/footer/footer';
import NavBarComponent from '../../components/navbar/nav-bar';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/auth';
import { withRouter } from 'react-router-dom';
import { authUserInvoke } from '../../redux/actions/auth';
import Routes from '../routes';

class MainComponent extends Component {
  componentDidMount() {
    document.title = 'FreeRabba';
    this.props.history.listen(() => {
      window.scroll(0, 0);
    });
  }

  handleAuthentication = (authResult) => {
    if (authResult.accessToken) {
      this.props.authUserInvoke(authResult);
    }
  };

  render() {
    return (
      <div className="flex-app">
        <NavBarComponent
          auth={this.props.auth}
          onLoggedIn={this.handleAuthentication}
          logoutUser={this.props.logoutUser}
          profile={this.props.profile}
        />
        <div className={'content'}>
          <Routes auth={this.props.auth} />
        </div>
        <FooterComponent auth={this.props.auth} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  auth,
  profile
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  authUserInvoke: (response) => dispatch(authUserInvoke(response))
  // updateUserProfile: profile => dispatch(updateUserProfile(profile))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainComponent));
