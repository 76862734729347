import React, { Component } from 'react';
import _get from 'lodash/get';
import logo from '../../images/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import AuthComponent from '../auth';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class NavBarComponent extends Component {
  state = {
    menuVisible: false,
    subMenu: null
  };

  componentDidUpdate(prevProps) {
    this.props.history.listen(() => {
      this.setState({
        menuVisible: false,
        subMenu: null
      });
    });

    // Logout scenario
    if (prevProps.auth.isAuth && !this.props.auth.isAuth) {
      this.props.history.push('/');
    }
  }

  renderLinksDesktop = () => {
    const { isAuth, user } = this.props.auth;
    const showLogIn = !isAuth;

    return (
      <ul className="nav navbar-nav navbar-right text-center">
        {!isAuth && (
          <li className="active">
            <Link to="/">Home</Link>
          </li>
        )}
        {!isAuth && (
          <li>
            <Link to="/howitworks">How it works</Link>
          </li>
        )}
        {isAuth && (
          <li>
            <Link to="/">Games</Link>
          </li>
        )}
        {/* isAuth && <li><Link to="/wallet">Wallet</Link></li> */}
        {isAuth && (
          <li className="dropdown">
            {/* <Link to="/profile">
            Account
            <Image
              src={user.profile_pic}
              avatar/>
          </Link> */}
            <a
              href="#/"
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Image src={_get(user, 'profile_pic')} avatar />
              <span className="dropdown-top-label">
                {this.props.profile.first_name}
              </span>
              <span className="caret"></span>
            </a>
            <ul className="dropdown-menu">
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/account">Account</Link>
              </li>
              <li>
                <Link to="/account">
                  <div className="split-col">
                    <span>Wallet</span>
                    <span className="badge">
                      ₦{_get(this.props, 'profile.wallet.balance', 0)}
                    </span>
                  </div>
                </Link>
              </li>
              <li role="separator" className="divider"></li>
              {isAuth && (
                <li className="f-signin no-hover">
                  <div>
                    <a href="#/" onClick={this.props.logoutUser}>
                      Log Out{' '}
                    </a>
                  </div>
                </li>
              )}
            </ul>
          </li>
        )}
        {showLogIn && (
          <li className="f-signin">
            <AuthComponent onFacebookResponse={this.props.onLoggedIn} />
          </li>
        )}
      </ul>
    );
  };

  renderLinksMobile = () => {
    const { isAuth, user } = this.props.auth;
    const showLogIn = !isAuth;

    return (
      <ul
        className={`hamb-menu-wrap ${this.state.menuVisible ? 'visible' : ''}`}
      >
        {!isAuth && (
          <li className="active">
            <Link to="/">Home</Link>
          </li>
        )}
        {!isAuth && (
          <li>
            <Link to="/howitworks">How it works</Link>
          </li>
        )}
        {isAuth && (
          <li>
            <Link to="/">Games</Link>
          </li>
        )}
        {/* isAuth && <li><Link to="/wallet">Wallet</Link></li> */}
        {isAuth && user && (
          <li
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                subMenu: this.state.subMenu === 'account' ? null : 'account'
              });
            }}
          >
            <div className="with-submenu">
              <div className="sub-menu">
                {/* <Link to="/profile"> */}
                <span className="sub-menu-label">
                  {this.props.profile.first_name}
                </span>
                {/* </Link> */}
              </div>
              <i
                className={`fa ${
                  this.state.subMenu === 'account'
                    ? 'fa-chevron-down'
                    : 'fa-chevron-right'
                }`}
              ></i>
            </div>
            <div
              className={`sub-menu-wrap ${
                this.state.subMenu === 'account' ? 'visible' : ''
              }`}
            >
              <ul>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/account">Account</Link>
                </li>
                <li>
                  <Link to="/account">
                    <div className="split-col">
                      <span>Wallet</span>
                      <span className="badge">
                        ₦{_get(this.props, 'profile.wallet.balance', 0)}
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        )}
        {/* <li><Link to="/sponsor">How to sponsor</Link></li> */}
        {isAuth && (
          <li className="f-signin">
            <a href="#/" onClick={this.props.logoutUser}>
              Log Out{' '}
            </a>
          </li>
        )}
        {showLogIn && (
          <li className="f-signin">
            <AuthComponent onFacebookResponse={this.props.onLoggedIn} />
          </li>
        )}
        <div
          className="menu-overlay"
          onClick={() =>
            this.setState({
              menuVisible: false
            })
          }
        ></div>
      </ul>
    );
  };

  render() {
    return (
      <nav className="navbar navbar-default">
        <div className="container">
          <div className="navbar-header">
            <span
              className="navbar-brand"
              onClick={() => this.props.history.push('/')}
            >
              <img src={logo} alt="Free Rabba" />
            </span>

            {window.matchMedia('(max-width: 767px)').matches && (
              <div className="right-hamb-wrap">
                {this.props.auth.isAuth && this.props.auth.user && (
                  <Link to="/profile">
                    <Image src={this.props.auth.user.profile_pic} avatar />
                  </Link>
                )}
                <div
                  className="hamb-menu"
                  onClick={() =>
                    this.setState({
                      menuVisible: !this.state.menuVisible
                    })
                  }
                >
                  {this.state.menuVisible ? (
                    <i className="fa fa-bars"></i>
                  ) : (
                    <i className="fa fa-bars"></i>
                  )}
                </div>
              </div>
            )}
            {window.matchMedia('(max-width: 767px)').matches &&
              this.renderLinksMobile()}
          </div>
          {!window.matchMedia('(max-width: 767px)').matches && (
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              {this.renderLinksDesktop()}
            </div>
          )}
        </div>
      </nav>
    );
  }
}

NavBarComponent.propTypes = {
  auth: PropTypes.any.isRequired,
  profile: PropTypes.object,
  onLoggedIn: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
};

export default withRouter(NavBarComponent);
