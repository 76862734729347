import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class AuthComponent extends React.Component {
  static defaultProps = {
    label: 'Sign in'
  };

  handleRender(renderProps) {
    return (
      <Link to={'/'} onClick={renderProps.onClick}>
        <i className="fab fa-facebook-f" /> {this.props.label}{' '}
      </Link>
    );
  }
  render() {
    return (
      <FacebookLogin
        appId="268751370664405"
        autoLoad
        fields="name,email,picture,first_name,last_name"
        callback={this.props.onFacebookResponse}
        render={(rp) => this.handleRender(rp)}
      />
    );
  }
}

AuthComponent.propTypes = {
  onFacebookResponse: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default AuthComponent;
