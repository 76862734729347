import React, { Component } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

class RulesComponent extends Component {
  state = { activeIndex: null };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  componentDidMount() {
    document.title = 'Rules';
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <section className="rules-page">
          <div className="container">
            <h2 className="wow bounceInLeft">Game Rules</h2>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Daily Quiz
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 0}>
                <ul>
                  <li>
                    Click on Daily Draw, if you have not played the game for
                    that day, you will be prompted to click a button to play
                  </li>
                  <li>
                    The daily draw has five (5) trivial questions. You have 10
                    seconds to answer each question. Click on next when you are
                    done
                  </li>
                  <li>
                    After answering all five (5) questions, click on submit to
                    submit your answer
                  </li>
                  <li>
                    You need to get at least 3 question of the 5 correct to
                    stand a chance of winning
                  </li>
                  <li>
                    At the expiration of that day's quiz, 3 winners will be
                    selected randomly from all those participants who scored
                    more than 3
                  </li>
                  <li>
                    The winners will automatically have their winnings added to
                    their wallet
                  </li>
                  <li>
                    Daily draw runs daily from 6pm of any day till 6pm the
                    fullowing day. Monday to Sunday
                  </li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Weekly Draw
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 1}>
                <ul>
                  <li>
                    To participate in the Weekly Draw, you need to have played
                    the daily draw a minimum of 4 times in any given week
                  </li>
                  <li>
                    If you meet the criteria given above, you will be
                    automatically entered into the draw for the week
                  </li>
                  <li>Every Friday at 6pm, a winner will be randomly chosen</li>
                  <li>
                    Every winner has 24 hours from the draw ending to claim
                    their prize
                  </li>
                  <li>
                    Should a prize remain unclaimed, it will rollover to the
                    following week.
                  </li>
                  <li>There is only one winner of the Weekly Draw</li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Withdrawals
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 2}>
                <ul>
                  <li>
                    Complete the account details page on your profile, with your
                    bank details
                  </li>
                  <li>
                    Click on withdraw and indicate the amount you would like to
                    withdraw
                  </li>
                  <li>Click submit to commence the process</li>
                  <li>
                    Give 2-3 working days for withdrawals to be processed and
                    funds appear in your account
                  </li>
                  <li>Minimum withdrawal is 500Naira</li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Referrals
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 3}>
                <ul>
                  <li>
                    So, you are having fun and would like to tell your friends.
                    Let us pay you to tell them.
                  </li>
                  <li>
                    Using the icons at the bottom of each page, share on your
                    favourite social media channel
                  </li>
                  <li>
                    You can also copy a url from your profile page to send to
                    your friends
                  </li>
                  <li>
                    Each time a new participant signs in via your referral
                    link/code, you are paid
                  </li>
                  <li>
                    Check your account dashboard to keep track of your referrals
                    and the amount earned. If you have questions, we are more
                    than happy to answer them.
                  </li>
                </ul>
              </Accordion.Content>
              {/* <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                Tetris
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 2}>
                <ul>
                  <li>Lorem</li>
                  <li>Ipsum</li>
                  <li>Dulor</li>
                  <li>Sit</li>
                  <li>Amet</li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                Flash Draw
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 3}>
                <ul>
                  <li>Lorem</li>
                  <li>Ipsum</li>
                  <li>Dulor</li>
                  <li>Sit</li>
                  <li>Amet</li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                Sudoku
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 4}>
                <ul>
                  <li>Lorem</li>
                  <li>Ipsum</li>
                  <li>Dulor</li>
                  <li>Sit</li>
                  <li>Amet</li>
                </ul>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                Top Videos
              </Accordion.Title>
              <Accordion.Content className="expand" active={activeIndex === 5}>
                <ul>
                  <li>Lorem</li>
                  <li>Ipsum</li>
                  <li>Dulor</li>
                  <li>Sit</li>
                  <li>Amet</li>
                </ul>
              </Accordion.Content>*/}
            </Accordion>
          </div>
        </section>
      </div>
    );
  }
}

export default RulesComponent;
