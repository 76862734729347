import {
  fetchWinnersInvoked,
  fetchWinnersSuccess,
  fetchWinnersFailed,
  FETCH_WINNERS_DATA
} from '../../actions/winners-action';
import { fetchData } from '../../../services/winners';
import { put, call, takeLatest } from 'redux-saga/effects';

function* fetchWinnersGen() {
  try {
    yield put(fetchWinnersInvoked());
    const payload = yield call(fetchData);
    yield put(fetchWinnersSuccess(payload));
  } catch (ex) {
    yield put(fetchWinnersFailed(ex));
  }
}

export default function* () {
  yield takeLatest(FETCH_WINNERS_DATA, fetchWinnersGen);
}
