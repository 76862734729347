import {
  FETCH_DAILY_DRAW_SUCCESS,
  FETCH_DAILY_DRAW_QUESTIONS_SUCCESS,
  INCREASE_QUESTION_INDEX,
  SUBMIT_ANSWERS_INVOKED,
  SUBMIT_ANSWERS_SUCCESS,
  SET_QUIZ_TIMER,
  FETCH_DAILY_DRAW_WINNERS_SUCCESS,
  FETCH_DAILY_DRAW_WINNERS_FAILED,
  FETCH_DAILY_DRAW_WINNERS_INVOKED,
  RESET_QUIZ,
} from '../../actions/daily-draw';
import { AUTH_USER_LOG_OUT_SUCCESS } from '../../actions/auth';

const initState = {
  daily_quiz: null,
  activeQuestionIndex: null,
  activeQuestion: null,
  startPlay: false,
  finishPlay: false,
  questions: [],
  winners: [],
  error: null,
  quizTimer: null,
};
export default (state = initState, action) => {
  switch (action.type) {
  case FETCH_DAILY_DRAW_SUCCESS:
    return {
      ...state,
      daily_quiz: action.payload
    };
  case FETCH_DAILY_DRAW_QUESTIONS_SUCCESS:
    return {
      ...state,
      questions: action.payload,
      activeQuestionIndex: 0,
      startPlay: true
    };

    case 'FETCH_DAILY_DRAW_WINNERS_SUCCESS':
    return {
      ...state,
      winners: action.payload,
      error: null,
      loading: false,
    };

    case 'FETCH_DAILY_DRAW_WINNERS_FAILED':
      return {
        ...state,
        winners: [],
        loading: false,
        error: action.payload
      };

      case 'FETCH_DAILY_DRAW_WINNERS_INVOKED':
        return {
          ...state,
          loading: true
        };

  case SUBMIT_ANSWERS_INVOKED:
    return {
      ...state,
      startPlay: false,
      finishPlay: true,
      activeQuestionIndex: null,
      questions: [],
      quizTimer: null
    };

  case SUBMIT_ANSWERS_SUCCESS:
    return {
      ...state,
      quizResult: action.payload
    };

  case AUTH_USER_LOG_OUT_SUCCESS:
    return {};

  case INCREASE_QUESTION_INDEX:
    return {
      ...state,
      questions: state.questions.map((q, index) => {
        if (index === action.payload.questionIndex) {
          return {
            ...q,
            answer: action.payload.answer
          };
        }
        return q;
      }),
      activeQuestionIndex: action.payload.questionIndex + 1
    };
  case SET_QUIZ_TIMER:
    return {
      ...state,
      quizTimer: action.time
    };
  case RESET_QUIZ:
    return { ...initState };
  default:
    return state;
  }
};
