import React, { Component } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import PaymentFormComponent from './payment-form';
import { connect } from 'react-redux';
import {
  fetchUserProfile,
  updatePaymentDetails,
  withdrawBalance
} from '../../redux/actions/profile';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

class AccountComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: [0],
      editMode: false,
      paymentDetails: _cloneDeep(props.paymentDetails)
    };
  }

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newActiveIndex = [...activeIndex];
    if (newActiveIndex.indexOf(index) >= 0) {
      newActiveIndex.splice(newActiveIndex.indexOf(index), 1);
    } else {
      newActiveIndex.push(index);
    }
    // const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newActiveIndex });
  };

  handlePaymentChange = (paymentDetails) => {
    this.setState({
      paymentDetails
    });
  };

  submitPaymentForm = () => {
    this.props.updatePaymentDetails(this.state.paymentDetails);
    this.setState({
      editMode: false
    });
  };

  resetForm = () => {
    this.setState({
      paymentDetails: _cloneDeep(this.props.paymentDetails),
      editMode: false
    });
  };

  formIsDirty = () => {
    if (_isEmpty(_trim(this.state.paymentDetails.account_number))) {
      return true;
    }
    if (_isEmpty(_trim(this.state.paymentDetails.provider))) {
      return true;
    }
    return false;
  };

  shareOnFB = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://www.facebook.com/sharer/sharer.php?u=https://freerabba.com/?uref=${this.props.auth.user.refCode}&t=Win money by playing games at FreeRabba`;
    } else {
      url =
        'https://www.facebook.com/sharer/sharer.php?u=https://freerabba.com/&t=Win money by playing games at FreeRabba';
    }
    window.open(
      url,
      'FBWindow',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
    );
    return false;
  };

  shareOnTwitter = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://twitter.com/intent/tweet?url=https://freerabba.com/?uref=${this.props.auth.user.refCode}&via=freerabba&text=Win money by playing games at FreeRabba`;
    } else {
      url =
        'https://twitter.com/intent/tweet?url=https://freerabba.com&via=freerabba&text=Win money by playing games at FreeRabba';
    }
    window.open(url, 'TwitterWindow', 'width=600,height=300');
    return false;
  };

  shareOnWhatsApp = () => {
    let url;
    if (_get(this.props, 'auth.user.refCode')) {
      url = `https://api.whatsapp.com/send?text=Win money by playing games at FreeRabba www.freerabba.com/?uref=${this.props.auth.user.refCode}`;
    } else {
      url =
        'https://api.whatsapp.com/send?text=Win money by playing games at FreeRabba www.freerabba.com';
    }
    window.open(url, 'WhatsappWindow', 'width=600,height=500');
    return false;
  };

  withdrawAmount = () => {
    if (
      !_isEmpty(_get(this.state.paymentDetails, 'account_name', '')) &&
      !_isEmpty(_get(this.state.paymentDetails, 'provider', ''))
    ) {
      // this.props.withdrawBalance(500 + Math.round(Math.random() * 100, 10));
      this.props.withdrawBalance(this.props.wallet.balance);
    } else {
      toastr.info(
        'Missing Info',
        'Please enter Payment Details first and try again'
      );
      this.setState({
        activeIndex: [1],
        editMode: true
      });
    }
  };

  onFormChange = (paymentFormIsValid) => {
    this.setState({
      paymentFormIsValid
    });
  }

  render() {
    if (!this.state.paymentDetails) return null;
    const { activeIndex } = this.state;

    return (
      <div>
        <section className="account-page">
          <div className="container">
            <div className="head-area">
              <h2 className="wow bounceInLeft">Account</h2>
              <div className="refer-wrap">
                <div>Invite your friends to earn:</div>
                <div className="social">
                  <i className="fab fa-facebook-f" onClick={this.shareOnFB}></i>
                  <i
                    className="fab fa-twitter"
                    onClick={this.shareOnTwitter}
                  ></i>
                  <i
                    className="fab fa-whatsapp"
                    onClick={this.shareOnWhatsApp}
                  ></i>
                </div>
              </div>
            </div>
            <div className="accordion-wrap">
              <Accordion fluid styled>
                <Accordion.Title
                  active={activeIndex.indexOf(0) >= 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Summary
                </Accordion.Title>
                <Accordion.Content
                  className="expand"
                  active={activeIndex.indexOf(0) >= 0}
                >
                  <div className="summary-cards">
                    <div className="summary-card-wrap">
                      <div className="summary-card">
                        <div className="card-label">Wallet Balance</div>
                        <div className="card-val-wrap">
                          <div className="card-val">
                            ₦{_get(this.props, 'wallet.balance', 0)}
                          </div>
                          {_get(this.props, 'wallet.balance', 0) >= 250 ? (
                            <button
                              type="button"
                              onClick={() => this.withdrawAmount()}
                            >
                              Withdraw
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="disabled"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Minimum amount required to withdraw is ₦250"
                            >
                              Withdraw
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="summary-card-wrap">
                      <div className="summary-card">
                        <div className="card-label">Total Winnings</div>
                        <div className="card-val">₦0.0</div>
                      </div>
                    </div>
                    <div className="summary-card-wrap">
                      <div className="summary-card">
                        <div className="card-label">Referral Earnings</div>
                        <div className="card-val">₦0.0</div>
                      </div>
                    </div>
                  </div>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex.indexOf(1) >= 0}
                  index={1}
                  onClick={this.handleClick}
                >
                  <div className="title-wrap">
                    <div>
                      <Icon name="dropdown" />
                      Payment Details
                    </div>
                    <div>
                      {activeIndex.indexOf(1) >= 0 && !this.state.editMode && (
                        <div
                          className="edit-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              editMode: true
                            });
                          }}
                        >
                          <i className="fas fa-pencil-alt"></i> Edit
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  className="expand"
                  active={activeIndex.indexOf(1) >= 0}
                >
                  <div className="accordion-content-wrap">
                    <PaymentFormComponent
                      onPaymentChange={this.handlePaymentChange}
                      paymentDetails={this.state.paymentDetails}
                      setFormValidity={this.onFormChange}
                      editMode={this.state.editMode}
                    />
                    {this.state.editMode && (
                      <div className="verify text-center">
                        <div className="container">
                          <button
                            className="btn save"
                            disabled={this.formIsDirty() || !this.state.paymentFormIsValid}
                            onClick={this.submitPaymentForm}
                          >
                            Save
                          </button>
                          <button
                            className="btn cancel"
                            onClick={this.resetForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex.indexOf(2) >= 0}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Withdrawal History
                </Accordion.Title>
                <Accordion.Content
                  className="expand"
                  active={activeIndex.indexOf(2) >= 0}
                >
                  <div className="accordion-content-wrap">
                    <table className="table">
                      <thead>
                        <tr className="header-row">
                          <th className="cell">Txn ID</th>
                          <th className="cell">Date</th>
                          <th className="cell">Amount</th>
                          <th className="cell">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_get(this.props, 'wallet.transactions', []).length >
                        0 ? (
                            _get(this.props, 'wallet.transactions', []).map(
                              (t) => {
                                const date = new Date(t.date_created);
                                return (
                                  <tr
                                    className="table-row"
                                    key={t.transaction_id}
                                  >
                                    <td className="cell">{t.transaction_id}</td>
                                    <td className="cell">
                                      {date.getUTCDate()}{' '}
                                      {months[date.getUTCMonth()]},{' '}
                                      {date.getUTCFullYear()} &nbsp;
                                      {('0' + date.getUTCHours()).slice(-2)}:
                                      {('0' + date.getUTCMinutes()).slice(-2)}:
                                      {('0' + date.getUTCSeconds()).slice(-2)}
                                    </td>
                                    <td className="cell">₦{Math.abs(t.value)}</td>
                                    <td className="cell">₦{t.running_balance}</td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr className="table-row">
                              <td className="cell text-center">
                              No transactions to show.
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Content>

                {/* <Accordion.Title
                  active={activeIndex.indexOf(3) >= 0}
                  index={3}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  Winnings Breakdown
                </Accordion.Title>
                <Accordion.Content className="expand" active={activeIndex.indexOf(3) >= 0}>
                  <div className="accordion-content-wrap">
                    <div className="table">
                      <div className="header-row">
                        <div className="cell">Game</div>
                        <div className="cell">Winnings (7 Days)</div>
                        <div className="cell">Winnings (30 Days)</div>
                      </div>
                      <div className="table-row">
                        <div className="cell">Daily Quiz</div>
                        <div className="cell">₦5.00</div>
                        <div className="cell">₦5.00</div>
                      </div>
                      <div className="table-row">
                        <div className="cell">Lottery</div>
                        <div className="cell">₦5.00</div>
                        <div className="cell">₦25.00</div>
                      </div>
                      <div className="table-row">
                        <div className="cell">Weekly Draw</div>
                        <div className="cell">₦10.00</div>
                        <div className="cell">₦20.00</div>
                      </div>
                      <div className="table-row">
                        <div className="cell">Sudoku</div>
                        <div className="cell">₦14.00</div>
                        <div className="cell">₦35.00</div>
                      </div>
                    </div>
                  </div>
                </Accordion.Content> */}
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  updatePaymentDetails: (payload) => dispatch(updatePaymentDetails(payload)),
  withdrawBalance: (amt) => dispatch(withdrawBalance(amt))
});

const mapStateToProps = ({ profile, auth }) => ({
  paymentDetails: profile.paymentDetails,
  auth,
  wallet: profile.wallet
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent);
