import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { default as reducers } from './reducers';

export { default as rootSaga } from './sagas/';

export const rootReducer = combineReducers({
  ...reducers,
  router: routerReducer,
  toastr: toastrReducer
});
