import moment from 'moment';
import { ACCESS_TOKEN } from '../../services/auth';

export function toQueryString(data) {
  function parseDataType(data) {
    if (moment.isMoment(data)) return data;
    if (typeof data === 'object' && data !== null) {
      return JSON.stringify(data);
    }
    return data;
  }
  return Object.keys(data)
    .map((k) => {
      return (
        encodeURIComponent(k) + '=' + encodeURIComponent(parseDataType(data[k]))
      );
    })
    .join('&');
}

export function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getAccessToken() {
  const fromStorage = localStorage.getItem(ACCESS_TOKEN);
  const accessToken = fromStorage
    ? fromStorage
    :'pLvtOx1D0OlpSA3fypSc1wdu7t9SEP';
  return `Bearer ${accessToken}`;
}
