import {
  // handleErrors,
  toQueryString,
  getAccessToken
} from '../helpers';

export const request = async (method, url, params, opts, contentType) => {
  const merged = {
    method: method || 'get',
    headers: {
      Authorization: getAccessToken()
    },
    ...opts
  };

  if (params) {
    merged.body =
      contentType === 'application/x-www-form-urlencoded'
        ? params
        : JSON.stringify(params);
    merged.headers.Accept = 'application/json';
    merged.headers['Content-Type'] = contentType || 'application/json';
  }

  return (
    fetch(url, merged)
      // .then(handleErrors)
      .then((response) => response)
      .catch((error) => error)
  );
};

export const get = async (url, params, opts) => {
  if (params) {
    const qs = toQueryString(params);
    if (url.indexOf('?') > 0) {
      url += qs;
    } else {
      url += `?${qs}`;
    }
  }

  return request('get', url, undefined, opts);
};

export const post = async (url, params, opts, contentType) => {
  return request('post', url, params, opts, contentType);
};

export const put = async (url, params, opts, contentType) => {
  return request('put', url, params, opts, contentType);
};
