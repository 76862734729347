import React, { Component } from 'react';

class HowToClaimComponent extends Component {
  componentDidMount() {
    document.title = 'How to Claim';
  }
  render() {
    return (
      <div>
        <section className="how-to-claim">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-xs-12">
                <h2 className="wow bounceInLeft">How to Claim</h2>
                <p>
                  For winners of daily draw, your winnings will be automatically credited to your account.
                  If you are a winner, logon to your account and view your wallet info to confirm.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HowToClaimComponent;
