import { getConfig } from '../../apiConfig';
import { AUTH, REF_CODE, USER_DETAILS } from '../../common/constants';
import { handleErrors } from '../../common';
import { get, post } from '../../common/apiClient';
// import winners from '../../redux/reducers/winners/winners-reducer';
// import auth from '../../redux/reducers/auth';

export const PREFIX = 'freerabba';
export const ACCESS_TOKEN = `${PREFIX}_access_token`;
export const REFRESH_TOKEN = `${PREFIX}_refresh_token`;
export const AUTH_USER = `${PREFIX}_auth_user`;
export const EXPIRES_AT = `${PREFIX}_expires_at`;
export const APP_STATE = `${PREFIX}:state`;

export const authUser = async ({ response }) => {
  const url = getConfig(AUTH).url;
  if (url) {
    return post(url, response)
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => {
        if (er.code === 401) handleErrors({ status: 401 });
      });
  }
  return Promise.reject(new Error(`Missing ${AUTH} url`));
};

export const getRefCode = async () => {
  const url = getConfig(REF_CODE).url;
  return get(url)
    .then(handleErrors)
    .then((res) => res.json())
    .catch((er) => {
      if (er.code === 401) handleErrors({ status: 401 });
    });
};

export const getUserDetails = () => {
  const url = getConfig(USER_DETAILS).url;
  return get(url)
    .then(handleErrors)
    .then((res) => res.json())
    .catch((er) => {
      if (er.code === 401) handleErrors({ status: 401 });
    });
};

export const setSession = (authResult) => {
  // const expiresAt = JSON.stringify((authResult.expiry_time * 1000) + new Date().getTime());
  const expiresAt = JSON.stringify(authResult.expiry_time * 1000);

  localStorage.setItem(ACCESS_TOKEN, authResult.access_token);
  localStorage.setItem(EXPIRES_AT, expiresAt);
  localStorage.setItem(REFRESH_TOKEN, authResult.refresh_token);
  localStorage.setItem(AUTH_USER, JSON.stringify(authResult.user));
};

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(AUTH_USER);
  localStorage.removeItem('freeRabba:state');
};

export const isAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT));
  return new Date().getTime() < expiresAt;
};

export class StateLoader {
  loadState() {
    try {
      const serializedState = localStorage.getItem(APP_STATE);

      if (!serializedState) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('freeRabba:state', serializedState);
    } catch (err) {
      console.log(err);
    }
  }

  initializeState() {
    return {
      winners: [],
      auth: isAuthenticated(),
      access_token: null,
      refresh_token: null,
      canUseSite: false,
      error: null,
      user: null,
      isNewUser: true,
      isAddressComplete: null,
      /* profile: {
        first_name: 'I AM',
        last_name: 'I AM',
        dob: '12/03/1979',
        address: {
          street_address: 'No 15 Andrew close',
          postcode: 'DE23 12E',
          city: 'Derby',
          // country: 'United Kingdom'
        },
        contact: {
          email: 'IAM@gmail.com',
          mobile: '07926117077'
        },
        gender: 'Male',
        paymentDetails: {
          account_name: 'Bolaji Adeyemi',
          provider: 'Llodys'
        },
        password: ''
      } */
      profile: {
        first_name: '',
        last_name: '',
        dob: '',
        address: {
          street_address: '',
          // postcode: '',
          city: '',
          state: '',
          // country: '',
          house_number: ''
        },
        contact: {
          email: '',
          mobile: ''
        },
        gender: '',
        paymentDetails: {
          account_number: '',
          provider: ''
        }
        // password: ''
      },
      refCode: null
    };
  }
}
