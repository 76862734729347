import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  NotFound,
  Winners,
  Profile,
  Account,
  HowItWorks,
  DailyQuiz,
  Games,
  Sponsor,
  Rules,
  Claim,
  Privacy
} from '../../components/';
import WithAddressCheck from '../../hoc/withAddressCheck';

export const HOW_IT_WORKS_ROUTE = 'howitworks';
export const ABOUT_US_ROUTE = 'aboutus';
export const SPONSOR_ROUTE = 'sponsor';
export const LOGIN_ROUTE = 'login';
export const DAILY_QUIZ_ROUTE = 'dailyquiz';
export const PROFILE_ROUTE = 'profile';
export const ACCOUNT_ROUTE = 'account';
export const GAMES_ROUTE = 'games';
export const WALLET_ROUTE = 'wallet';
export const RULES_ROUTE = 'rules';
export const PRIVACY_ROUTE = 'privacy-policy';
export const CLAIM_ROUTE = 'how-to-claim';

export const fullPages = {
  HOW_IT_WORKS_ROUTE,
  ABOUT_US_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  GAMES_ROUTE,
  WALLET_ROUTE,
  SPONSOR_ROUTE
};

const Routes = ({ auth }) => (
  <Switch>
    <Route
      exact
      path="/"
      component={() => {
        if (auth.isAuth && !auth.canUseSite) {
          return (
            <WithAddressCheck>
              <Games />
            </WithAddressCheck>
          );
        }
        return <Winners />;
      }}
    />
    <Route
      exact
      path={`/${PROFILE_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Profile />
        </WithAddressCheck>
      )}
    />
    <Route
      exact
      path={`/${ACCOUNT_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Account />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${HOW_IT_WORKS_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <HowItWorks />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${DAILY_QUIZ_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <DailyQuiz />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${GAMES_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Games />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${ABOUT_US_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Winners />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${WALLET_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Winners />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${SPONSOR_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Sponsor />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${RULES_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Rules />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${CLAIM_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Claim />
        </WithAddressCheck>
      )}
    />
    <Route
      path={`/${PRIVACY_ROUTE}`}
      component={() => (
        <WithAddressCheck>
          <Privacy />
        </WithAddressCheck>
      )}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
