import { PROFILE } from '../../common/constants';

export const FETCH_USER_PROFILE_INVOKED = `${PROFILE}_FETCH_USER_PROFILE_INVOKED`;
export const FETCH_USER_PROFILE_SUCCESS = `${PROFILE}_FETCH_USER_PROFILE_SUCCESS`;
export const FETCH_USER_PROFILE_FAILED = `${PROFILE}_FETCH_USER_PROFILE_FAILED`;
export const FETCH_USER_PROFILE = `${PROFILE}_FETCH_USER_PROFILE`;
export const UPDATE_USER_PROFILE = `${PROFILE}_UPDATE_USER_PROFILE`;
export const INIT_USER_PROFILE = `${PROFILE}INIT_USER_PROFILE`;
export const UPDATE_PAYMENT_DETAILS = `${PROFILE}UPDATE_PAYMENT_DETAILS`;
// export const FETCH_PAYMENT_DATA_SUCCESS = `${PROFILE}FETCH_PAYMENT_DATA_SUCCESS`;
// export const FETCH_PAYMENT_DATA = `${PROFILE}FETCH_PAYMENT_DATA`;
export const INIT_WALLET_DETAILS = `${PROFILE}_INIT_WALLET_DETAILS`;
export const WITHDRAW_BALANCE = `${PROFILE}_WITHDRAW_BALANCE`;

export const fetchUserProfileInvoked = () => ({
  type: FETCH_USER_PROFILE_INVOKED
});

export const fetchUserProfile = () => ({
  type: FETCH_USER_PROFILE
});

export const fetchUserProfileSuccess = (payload) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload
});

export const fetchUserProfileFailed = () => ({
  type: FETCH_USER_PROFILE_FAILED
});

export const updateUserProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload
});

export const updatePaymentDetails = (payload) => ({
  type: UPDATE_PAYMENT_DETAILS,
  payload
});

export const initUserProfile = (payload) => ({
  type: INIT_USER_PROFILE,
  payload
});

export const initWalletDetails = (payload) => ({
  type: INIT_WALLET_DETAILS,
  payload
});

export const withdrawBalance = (amt) => ({
  type: WITHDRAW_BALANCE,
  payload: amt
});
