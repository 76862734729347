export const handleErrors = (response) => {
  if (!response.ok || response.status !== 200) {
    if (response.status === 401) {
      localStorage.clear();
    }
    const error = new Error(response.statusText);
    error.code = response.status;
    throw error;
  }
  return response;
};
